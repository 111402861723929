import {Options, Vue} from "vue-class-component";
import {Prop, Provide} from "vue-property-decorator";
import {classToPlain, plainToClass} from "class-transformer";
import {activitiesService} from "@services/activities.service";
import {ActivitiesRoutesEnum} from "@/modules/activities/router";
import {Activity} from "@/model/api/Activity";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";
import {PaymentTypeEnum} from "@/model/enums/PaymentTypeEnum";
import {projectsService} from "@services/projects.service";

@Options({
    components: {}
})
export default class ActivityPage extends Vue {

    @Prop()
    @Provide({reactive: true})
    readonly activityId!: String;

    activity: Activity = null;
    requiredFields: string[] = ['name', 'project_id', 'wrs_prefix', 'work_type', 'status', 'customer_id', 'zone_name', 'zone_id', 'payment_type', 'contract_id'];

    get isNew() {
        return this.activityId === 'new';
    }

    get backRoute() {
        return {name: ActivitiesRoutesEnum.ACTIVITY_LIST}
    }

    onSave() {

        if (this.activity.customer) {
            this.activity.customer_id = this.activity.customer.id
        }

        let areThereAnyErrors: boolean

        this.requiredFields.forEach((field) => {
            if(this.activity[field] === null || this.activity[field] === undefined || this.activity[field] === ''){
                this.$errorMessage(`Field ${field} is required`);
                areThereAnyErrors = true;
            }
        })

        if(areThereAnyErrors) return;

        // clean oppositve fields
        switch (this.activity.payment_type) {
            case PaymentTypeEnum.TON:
                this.activity.is_ton_requested = false;
                break;
            default:
                break;
        }

        this.$waitFor(

            async () => {
                if (this.isNew) {
                    const plainShift = classToPlain(this.activity);
                    const res = await activitiesService.create(plainShift as any)
                    this.activity = plainToClass(Activity, res)
                    await this.$router.replace({
                        name: ActivitiesRoutesEnum.ACTIVITY_DETAIL,
                        params: {
                            activityId: res.id
                        }
                    });
                    this.$successMessage("Activity successfully created");

                } else {
                    const plainShift = classToPlain(this.activity);
                    const response = await activitiesService.updatePatch(plainShift as any);
                    this.activity = plainToClass(Activity, response);
                    this.$successMessage("Activity successfully updated");
                }
            },
            "Saving failed"
        )
    }


    createWRS() {
        this.$router.push({
            name: ShiftsRoutesEnum.SHIFTS_DETAIL,
            params: {shiftId: 'new'},
            query: {activityId: this.activity.id}
        })
    }

    get tabs() {
        let i = 0;

        return [
            {
                label: this.$t('activity.tabs.data'),
                icon: 'fas fa-sticky-note',
                to: {name: ActivitiesRoutesEnum.ACTIVITY_DETAIL},
                class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
            },
            {
                label: this.$t('activity.tabs.kpi'),
                icon: 'fas fa-chart-pie',
                to: {name: ActivitiesRoutesEnum.ACTIVITY_KPI},
                disabled: this.isNew,
                class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
            },
            {
                label: this.$t('activity.tabs.tickets'),
                icon: 'fas fa-clipboard',
                to: {name: ActivitiesRoutesEnum.ACTIVITY_TICKETS},
                disabled: this.isNew,
                class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
            },
            {
                label: this.$t('activity.tabs.shifts'),
                icon: 'fas fa-road',
                to: {name: ActivitiesRoutesEnum.ACTIVITY_SHIFTS},
                disabled: this.isNew,
                class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
            }
        ];
    }

    private async _loadActivity() {
        this.$waitFor(
            async () => {
                this.activity = await activitiesService.getById(+this.activityId);
                ['target_hours', 'target_tons', 'target_cycles', 'target_budget'].forEach((key) => {
                    if(!this.activity[key] && this.activity[key] !== 0){
                        this.activity[key] = 0
                    }
                })
            }
        )
    }

    async created() {
        if (!this.isNew) {
            await this._loadActivity();
        } else {
            const activity = {
                zone_name: 'America/New_York',
                payment_type: PaymentTypeEnum.CYCLE,
                target_hours: 0,
                target_tons: 0,
                target_cycles: 0,
                target_budget: 0
            }
            this.activity = new Activity(activity);
            if (this.$route.query.projectId) {
                this.activity.project_id = +this.$route.query.projectId
                this.activity.project = await projectsService.getById(+this.$route.query.projectId);
            }
        }
    }

}
