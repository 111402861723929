import {Company} from "@/model/api/Company";
import {Project} from "@/model/api/Project";
import {Zone} from "@/model/api/Zone";
import {User} from "@/model/api/User";
import {Material} from "@/model/api/Material";
import {Contract} from "@/model/api/Contract";

export class Activity {
	id: number;
	name: string;
	description: string;
	wrs_prefix: string;
	status: boolean;
	cost_code: boolean;

	project_id: number;
	project: Project;

	zone: Zone;
	zone_id: number;

	zone_name: string;
	payment_type: number;
	wrs_description: string;

	material: Material;
	material_id: number;

	note_public: string;
	is_ton_requested: boolean;
	can_upload_photo_dump: boolean;
	can_upload_photo_load: boolean;

	contract_id: number;
	contract: Contract;

	foreman: User;
	foreman_id: number;

	target_tons: number;
	total_tons: number;

	target_budget: number;
	total_budget: number;

	total_hours: number;
	target_hours: number;

	total_cycles: number;
	target_cycles: number;

	job_site: string;
	poc_phone: string;
	poc_email: string;
	poc_name: string;

	customer: Company;
	customer_id: number;

	work_type: ActivityWorkTypeEnum;

	constructor(data) {
		Object.assign(this, data);
	}
}

export enum ActivityStatusEnum {
	OPEN = 10,
	CLOSED = 20
}

export enum ActivityWorkTypeEnum {
	IMPORT = 10,
	EXPORT = 20,
	ONSITE = 30
}
