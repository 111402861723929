import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-dac3ec1c")
const _hoisted_1 = { class: "p-mr-1" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DateFormatted = _resolveComponent("DateFormatted")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AppTable = _resolveComponent("AppTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_AppTable, {
    ref: "dataTable",
    "striped-rows": false,
    filtersSchema: _ctx.filters,
    service: _ctx.service,
    sortField: `created_at`,
    sortOrder: 1,
    onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDetail($event.data))),
    onEdit: _ctx.goToDetail
  }, {
    actions: _withCtx(({data}) => [
      _createElementVNode("span", _hoisted_1, [
        _withDirectives(_createVNode(_component_Button, {
          class: "p-button-info",
          icon: "pi pi-sitemap",
          onClick: _withModifiers(($event: any) => (_ctx.createWRS(data)), ["stop"])
        }, null, 8, ["onClick"]), [
          [
            _directive_tooltip,
            'Create WRS',
            void 0,
            { bottom: true }
          ]
        ])
      ])
    ]),
    columns: _withCtx(({onApplyFilter}) => [
      _createVNode(_component_Column, {
        field: "id",
        showFilterMatchModes: false,
        header: "Activity ID"
      }, {
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            placeholder: "Search by Activity ID",
            onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
        ]),
        filterclear: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.clear'),
            class: "p-button-outlined",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.apply'),
            class: "p-button-success",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_Column, {
        field: "created_at",
        header: "Created At",
        sortable: true,
        style: {"min-width":"12rem"}
      }, {
        body: _withCtx(({data}) => [
          _createVNode(_component_DateFormatted, {
            date: data.created_at,
            format: "YYYY-MM-DD hh:mm A"
          }, null, 8, ["date"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "name",
        header: "Activity",
        showFilterMatchModes: false
      }),
      _createVNode(_component_Column, {
        field: "customer.name",
        header: "Customer",
        showFilterMatchModes: false
      }, {
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            placeholder: "Search by Customer name",
            onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
        ]),
        filterclear: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.clear'),
            class: "p-button-outlined",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.apply'),
            class: "p-button-success",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_Column, {
        header: "Work Type",
        field: "work_type",
        showFilterMatchModes: false,
        showFilterOperator: false,
        sortable: true
      }, {
        body: _withCtx(({data}) => [
          _createTextVNode(_toDisplayString(_ctx.$t(`activity.work_type.${data.work_type}`)), 1)
        ]),
        filter: _withCtx(({filterModel}) => [
          _createVNode(_component_Dropdown, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            options: _ctx.workTypeOptions,
            placeholder: "Select status",
            "option-value": "value",
            "option-label": "label"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            label: "apply",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_Column, {
        header: "Status",
        field: "status",
        showFilterMatchModes: false,
        showFilterOperator: false
      }, {
        body: _withCtx(({data}) => [
          _createTextVNode(_toDisplayString(_ctx.$t(`activity.status.${data.status}`)), 1)
        ]),
        filter: _withCtx(({filterModel}) => [
          _createVNode(_component_Dropdown, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            options: _ctx.statusOptions,
            placeholder: "Select status",
            "option-value": "value",
            "option-label": "label"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            label: "apply",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["onClick"])
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["filtersSchema", "service", "onEdit"]))
}