import {Options, Vue} from "vue-class-component";
import {Inject, Prop} from "vue-property-decorator";
import {Project} from "@/model/api/Project";
import {shiftsService} from "@services/shifts.service";
import Table from "@components/AppTable/Table";
import {Shift, ShiftStatusEnum} from "@/model/api/Shift";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";
import {FilterMatchMode} from "primevue/api";
import {ActivitiesRoutesEnum} from "@/modules/activities/router";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {authStore} from "@/modules/auth/store";
import {AppTable} from "@components";
import {ProjectsRoutesEnum} from "@/modules/projects/router";

@Options({
    inheritAttrs: false,
    components: {
        AppTable
    }
})
export default class ProjectShifts extends Vue {

    @Inject()
    readonly projectId!: string;

    @Prop() project!: Project;

    @Prop() readonly isLoading!: boolean;

    filters: any = null;

    get service() {
        return shiftsService;
    }

    get table() {
        return this.$refs.dataTable as Table;
    }

    getRowClass({ trucks_engaged, trucks_min, trucks_required, status }: Shift) {
        if (status === ShiftStatusEnum.DISABLED) {
            return "shift_disabled";
        } else if (status === ShiftStatusEnum.TO_BE_APPROVED) {
            return "shift_tobeapp";
        }

        if(trucks_min == 0)
            trucks_min = 1;

        if (trucks_engaged >= trucks_required) {
            return "truck_required";
        } else if (
            trucks_engaged <= trucks_required &&
            trucks_engaged >= trucks_min
        ) {
            return "truck_min";
        }

        return null;
    }

    get imAdmin() {
        return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN
    }

    goToProjectDetails(shift: Shift){
        if(this.imAdmin){
            this.$router.push({
                name: ProjectsRoutesEnum.PROJECT_DETAIL,
                params: { projectId: shift.project_id }
            })
        }
    }

    goToDetail(shift: Shift) {
        this.$router.push({
            name: ShiftsRoutesEnum.SHIFTS_DETAIL,
            params: { shiftId: shift.id }
        })
    }

    private initFilter() {
        this.filters = {
            id: {
                value: null,
                matchMode: FilterMatchMode.STARTS_WITH
            },
            'project.id': {
                value: this.projectId,
                matchMode: FilterMatchMode.EQUALS
            },
            code: {
                value: null,
                matchMode: FilterMatchMode.STARTS_WITH
            },
            job_site: {
                value: null,
                matchMode: FilterMatchMode.STARTS_WITH
            },
            'customer.name': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            'project.name': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            'activity.name': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            status: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
        };
    }

    goToActivityDetails(shift: Shift){
        if(this.imAdmin){
            this.$router.push({
                name: ActivitiesRoutesEnum.ACTIVITY_DETAIL,
                params: { activityId: shift.activity_id }
            })
        }
    }

    update() {
        console.debug("update");
        this.table.applyFilter();
    }

    created() {
        this.initFilter();
    }

    get statusOptions() {
        return [
            { label: this.$t(`shift.status_${ShiftStatusEnum.ENABLED}`), value: ShiftStatusEnum.ENABLED },
            { label: this.$t(`shift.status_${ShiftStatusEnum.DISABLED}`), value: ShiftStatusEnum.DISABLED },
            { label: this.$t(`shift.status_${ShiftStatusEnum.TO_BE_APPROVED}`), value: ShiftStatusEnum.TO_BE_APPROVED }
        ];
    }

    get isStatusColumnVisible() {
        return [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CUSTOMER, UserRoleEnum.BROKER].includes(authStore.getters.me.role);
    }

    get isActionsColumnVisible() {
        return [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CUSTOMER].includes(authStore.getters.me.role);
    }
}
