import { Options, Vue } from "vue-class-component";
import moment from "moment";
import { classToPlain, plainToClass } from "class-transformer";

import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { ticketsService } from "@services/tickets.service";
import { Ticket } from "@/model/api/Ticket";
import { FilterMatchMode } from "primevue/api";

import {
  activeStatuses,
  finishStatuses,
  inactiveStatuses,
  TicketStatusEnum
} from "@/model/enums/TicketStatusEnum";

import {
  AppTable, MaterialSelect, PaymentStatus,
  SendMessageDialog, ShiftAutocomplete,
  TicketFinishStatus,
  TicketStatus,
} from "@components";

import Table from "@components/AppTable/Table";

import TicketDisputeResolverDialog
  from "@/components/TicketDisputeResolverDialog/TicketDisputeResolverDialog.vue";

import { fileDownload } from "@/utils/utils";
import { ShiftsRoutesEnum } from "@/modules/shifts/router";
import { CompaniesRoutesEnum } from "@/modules/companies/router";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";
import { authStore } from "@/modules/auth/store";
import { PaymentStatusEnum } from "@/model/enums/PaymentStatusEnum";
import Dropdown from "primevue/dropdown";
import {TicketsRoutesEnum} from "@/modules/tickets/router";
import {Inject, Prop} from "vue-property-decorator";
import {Activity} from "@/model/api/Activity";

@Options({
  components: {
    AppTable,
    SendMessageDialog,
    ShiftAutocomplete,
    TicketFinishStatus,
    TicketStatus,
    PaymentStatus,
    MaterialSelect,
    Dropdown,
    TicketDisputeResolverDialog,
  }
})
export default class ActivityTickets extends Vue {
  @Inject()
  readonly activityId!: string;

  @Prop() activity!: Activity;

  @Prop() readonly isLoading!: boolean;

  filters: any = null;

  exporting: boolean = false;

  onlyActive: boolean = true;

  selectedTicket: Ticket = null;

  displayResolverDialog: boolean = false;

  get service() {
    return ticketsService;
  }

  get detailRoute() {
    return TicketsRoutesEnum.TICKETS_DETAIL;
  }

  get selectedTicketId() {
    return this.selectedTicket?.id;
  }

  get costCodesOptions() {
    return []
  }

  get statusOptions() {
    const statuses = [...activeStatuses];

    if (!this.onlyActive) {
      statuses.push(...inactiveStatuses);
    }

    return statuses.map(s => ({
      label: this.$t(`ticket.statuses.${TicketStatusEnum[s]}`),
      value: s
    }))
  }

  get finishStatusOptions() {
    return finishStatuses.map(s => ({
      label: this.$t(`ticket.finish_status.${s}`),
      value: s
    }))
  }

  get shiftRoute() {
    return ShiftsRoutesEnum.SHIFTS_DETAIL;
  }

  get customerDetailRoute() {
    return CompaniesRoutesEnum.CUSTOMER_DETAIL;
  }

  get brokerDetailRoute() {
    return CompaniesRoutesEnum.BROKER_DETAIL;
  }

  get clientDetailRoute() {
    return CompaniesRoutesEnum.CLIENT_DETAIL;
  }

  get isColLinksActive() {
    return [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CUSTOMER].includes(authStore.getters.me.role);
  }

  get visibilityCols() {
    const me = authStore.getters.me;
    return {
      fee_driver: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CUSTOMER, UserRoleEnum.BROKER].includes(me.role),
      fee_broker: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CUSTOMER, UserRoleEnum.BROKER].includes(me.role),
      fee_customer: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CUSTOMER].includes(me.role),
      fee_system: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CUSTOMER].includes(me.role),
      fee_client: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CUSTOMER].includes(me.role)
    }
  }

  getDuration(ticket: Ticket) {
    if (!ticket.datetime_start?.isValid()) {
      return "-"
    }

    const startMoment = moment(ticket.datetime_start);
    let d = null;

    if (!ticket.datetime_end.isValid()) {
      d = moment.duration(moment().diff(startMoment));
    } else {
      const endMoment = moment(ticket.datetime_end);
      d = moment.duration(endMoment.diff(startMoment));
    }

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());
    const s = Math.floor(d.seconds());

    // const htext = this.$t('hours', h).trim();
    // const mtext = this.$t('minutes', m).trim();

    // return `${h} ${htext}` + (m ? ` ${m} ${mtext}` : '');
    return [h, m, s].map(x => x.toString().padStart(2, '0')).join(':');
  }

  canDelete(t: Ticket) {
    return [TicketStatusEnum.ACCEPTED_BY_USER, TicketStatusEnum.DELETED_BY_USER, TicketStatusEnum.DELETED_BY_SYSTEM].includes(t.status);
  }

  isDispute(t: Ticket) {
    return t?.status === TicketStatusEnum.DISPUTE;
  }

  rowClass(ticket: Ticket) {
    return ticket.status == TicketStatusEnum.DELETED_BY_USER || ticket.status == TicketStatusEnum.DELETED_BY_SYSTEM  ? 'row-deleted-by-user' : null;
  }

  trashLabel(ticket){
    return ticket.status === TicketStatusEnum.ACCEPTED_BY_USER ? this.$t('ticket.actions.decline') : this.$t('ticket.actions.delete')
  }

  async onExport(type: string) {
    try {
      const filters = (this.$refs.table as Table).filters;

      this.exporting = true

      const response = await ticketsService.exportFile(filters, type);

      this.exporting = false

      const fileName = type === 'invoice' ? 'invoice' : `TicketList_Export_${moment().format('YYYY_MM_DD')}`;

      fileDownload(
        fileName,
        response,
        type === 'excel' || type === 'invoice' ?
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :
          "application/pdf"
      )

      this.refreshTable();

    } catch (error) {
      console.error(error);
      this.exporting = false
      this.$errorMessage("Export failed");
    }
  }

  onResolve(t: Ticket) {
    this.selectedTicket = plainToClass(Ticket, classToPlain(t));
    this.displayResolverDialog = true;
  }

  onResolved() {
    this.displayResolverDialog = false;
    this.selectedTicket = null;

    this.$successMessage("Ticket dispute resolved");
  }

  onResolveCancel() {
    this.displayResolverDialog = false;
    this.selectedTicket = null;
  }

  onUpdateOnlyActive(val: boolean) {

    sessionStorage.setItem('ticketsOnlyActive', val.toString());

    const table = (this.$refs.table as Table);

    table.filters.status = {
      value: val ? activeStatuses : null,
      matchMode: FilterMatchMode.IN
    }

    table.onApplyFilter();
  }

  onEdit(ticket: Ticket) {
    this.$router.push({
      name: TicketsRoutesEnum.TICKETS_DETAIL,
      params: {
        ticketId: ticket.id
      }
    })
  }

  onDeleted() {
    this.selectedTicket = null;
    this.refreshTable();
    this.$successMessage("Ticket deleted successfully");
  }

  async onDelete(ticket: Ticket) {


        if(ticket.status === TicketStatusEnum.ACCEPTED_BY_USER){
      this.$confirmMessage(this.$t('ticket.messages.decline_confirm')).then(r => {
        if (r) {
          this.deleteTicket(ticket);
        }
      })
    } else {
      this.$confirmMessage(this.$t('ticket.messages.delete_confirm')).then(r => {
        if (r) {
          this.deleteTicket(ticket, true);
        }
      })
    }
     
  }

  private deleteTicket(ticket: Ticket, standard?) {
    this.$waitFor(
      async () => {
        if(!standard){
          await ticketsService.deleteBySystem(ticket);
        } else {
          await ticketsService.deleteStandard(ticket);
        }
        this.refreshTable();
        this.$successMessage("Ticket successfully deleted")
      },
      "Deleting Ticket failed"
    )
  }

  private refreshTable() {
    const table = (this.$refs.table as Table);
    table.applyFilter();
  }

  goToDetail(ticket: Ticket) {
    this.$router.push({
      name: TicketsRoutesEnum.TICKETS_DETAIL,
      params: { ticketId: ticket.id }
    })
  }

  onFiltersChange() {
    this._updateFilters();
  }

  onReset() {
    this.onlyActive = true;
    this._updateFilters();
  }

  private _updateFilters() {
    this._updateTable();
  }

  private _updateTable() {
    const table = (this.$refs.table as Table);
    table.applyFilter();
  }

  get paymentStatusOptions() {
    return [
      { label: this.$t(`ticket.payment_statuses.BLANK`), value: '' },
      { label: this.$t(`ticket.payment_statuses.PAID`), value: PaymentStatusEnum.PAID },
      { label: this.$t(`ticket.payment_statuses.PENDING`), value: PaymentStatusEnum.PENDING },
      { label: this.$t('ticket.payment_statuses.APPROVED'), value: PaymentStatusEnum.APPROVED },
      { label: this.$t(`ticket.payment_statuses.AUTHORIZED`), value: PaymentStatusEnum.AUTHORIZED },
    ]
  }

  private initFilter() {
    this.filters = {
      id: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      'shift.code': {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },

      status: {
        value: null,
        matchMode: FilterMatchMode.IN
      },
      payment_status: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      cost_code: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      'activity.id': {
        value: this.activityId,
        matchMode: FilterMatchMode.EQUALS
      },
      'shift.job_site': {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },

      material_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      finish_status: {
        value: null,
        matchMode: FilterMatchMode.IN
      },
      user_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      contract_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      customer_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      broker_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      client_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }

  created() {
    this.initFilter();
  }
}
