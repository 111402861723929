import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-col-4" }
const _hoisted_3 = { class: "p-col-4" }
const _hoisted_4 = { class: "p-col-4" }
const _hoisted_5 = { class: "p-col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Card = _resolveComponent("Card")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createTextVNode(" Tons: " + _toDisplayString(_ctx.activity?.total_tons) + " of " + _toDisplayString(_ctx.activity?.target_tons) + " : " + _toDisplayString(_ctx.percent_tons) + "% ", 1)
        ]),
        content: _withCtx(() => [
          (_ctx.activity)
            ? (_openBlock(), _createBlock(_component_Chart, {
                key: 0,
                type: "pie",
                data: _ctx.tonsData,
                options: _ctx.chartOptions,
                class: "w-full md:w-30rem"
              }, null, 8, ["data", "options"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createTextVNode(" Cycles: " + _toDisplayString(_ctx.activity?.total_cycles) + " of " + _toDisplayString(_ctx.activity?.target_cycles) + " : " + _toDisplayString(_ctx.percent_cycles) + "% ", 1)
        ]),
        content: _withCtx(() => [
          (_ctx.activity)
            ? (_openBlock(), _createBlock(_component_Chart, {
                key: 0,
                type: "pie",
                data: _ctx.cyclesData,
                options: _ctx.chartOptions,
                class: "w-full md:w-30rem"
              }, null, 8, ["data", "options"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createTextVNode(" Hours: " + _toDisplayString(_ctx.activity?.total_hours) + " of " + _toDisplayString(_ctx.activity?.target_hours) + " : " + _toDisplayString(_ctx.percent_time) + "% ", 1)
        ]),
        content: _withCtx(() => [
          (_ctx.activity)
            ? (_openBlock(), _createBlock(_component_Chart, {
                key: 0,
                type: "pie",
                data: _ctx.hoursData,
                options: _ctx.chartOptions,
                class: "w-full md:w-30rem"
              }, null, 8, ["data", "options"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createTextVNode(" Budget: " + _toDisplayString(_ctx.activity?.total_budget) + " of " + _toDisplayString(_ctx.activity?.target_budget) + " : " + _toDisplayString(_ctx.percent_budget) + "% ", 1)
        ]),
        content: _withCtx(() => [
          (_ctx.activity)
            ? (_openBlock(), _createBlock(_component_Chart, {
                key: 0,
                type: "pie",
                data: _ctx.budgetData,
                options: _ctx.chartOptions,
                class: "w-full md:w-30rem"
              }, null, 8, ["data", "options"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ], 512)), [
    [_vShow, !_ctx.isLoading]
  ])
}