import {Options, Vue} from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {Project} from "@/model/api/Project";
import {activitiesService} from "@services/activities.service";
import {FilterMatchMode} from "primevue/api";
import moment from "moment";

@Options({
    inheritAttrs: false,
    components: {}
})
export default class ProjectKPI extends Vue {

    cyclesData: any = null;
    tonsData: any = null;
    hoursData: any = null;
    budgetData: any = null;
    projectData: any = null;
    chartOptions: any = null
    stackedOptions: any = null;

    init(){
        this.cyclesData = {
            labels: [],
            datasets: [
                {
                    type: 'bar',
                    label: 'Total Cycles',
                    backgroundColor: '#66BB6A',
                    data: []
                },
                {
                    type: 'bar',
                    label: 'Target Cycles',
                    backgroundColor: '#42A5F5',
                    data: []
                }
            ]
        };


        this.tonsData = {
            labels: [],
            datasets: [
                {
                    type: 'bar',
                    label: 'Total Tons',
                    backgroundColor: '#FFA726',
                    data: []
                },
                {
                    type: 'bar',
                    label: 'Target Tons',
                    backgroundColor: '#42A5F5',
                    data: []
                }
            ]
        };

        this.hoursData = {
            labels: [],
            datasets: [
                {
                    type: 'bar',
                    label: 'Total Hours',
                    backgroundColor: '#EF5350',
                    data: []
                },
                {
                    type: 'bar',
                    label: 'Target Hours',
                    backgroundColor: '#42A5F5',
                    data: []
                }
            ]
        };

        this.budgetData = {
            labels: [],
            datasets: [
                {
                    type: 'bar',
                    label: 'Total Budget',
                    backgroundColor: '#6f42c1',
                    data: []
                },
                {
                    type: 'bar',
                    label: 'Target Budget',
                    backgroundColor: '#94C142',
                    data: []
                }
            ]
        };

        this.projectData = {
            tons: {
                total: 0,
                target: 0,
                expectedToday: 0,
                colors: ['#EF5350', '#2ea59f', '#FFA726']
            },
            cycles: {
                total: 0,
                target: 0,
                expectedToday: 0,
                colors: ['#66BB6A', '#42A5F5', '#6f42c1']
            },
            hours: {
                total: 0,
                target: 0,
                expectedToday: 0,
                colors: ['#FFA726', '#FF7043', '#2ea59f']
            },
            budget: {
                total: 0,
                target: 0,
                expectedToday: 0,
                colors: ['#6f42c1', '#94C142', '#2ea59f']
            }
        }
        this.chartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                }
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                }
            }
        }

        this.stackedOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                }
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                }
            }
        };

    }

    @Prop() project!: Project;

    @Prop() readonly isLoading!: boolean;

    @Watch('project')
    async onShiftChange() {
        this.init()
        return this.populateCharts()
    }

    async populateCharts(){
        if(this.project){

            const weekDaysFromStartToEnd = this.getWeekdays(this.project.expected_start, new Date(this.project.expected_end))
            const weekDaysFromStartToToday = this.getWeekdays(new Date(this.project.expected_start), new Date())

            const activities: any = await activitiesService.getAll({
                filters: {
                    project_id: {
                        value: this.project.id,
                        matchMode: FilterMatchMode.EQUALS
                    }
                }
            })

            activities.data.forEach(activity => {
                Object.keys(this.projectData).forEach(key => {
                    this[`${key}Data`].labels.push(activity.name)
                    this[`${key}Data`].datasets[0].data.push(activity[`total_${key}`])
                    this[`${key}Data`].datasets[1].data.push(activity[`target_${key}`])
                    this.projectData[key].total += activity[`total_${key}`]
                    this.projectData[key].target += activity[`target_${key}`]
                });
            })

            Object.keys(this.projectData).forEach(key => {
                this.projectData[key].expectedToday = Number((this.projectData[key].target / weekDaysFromStartToEnd) * weekDaysFromStartToToday).toFixed(2).replace(/[.,]00$/, "");
                this.projectData[key].data = this.setValueData(key);
            });
        }
    }

    getWeekdays(startDate, endDate) {
        let weekdays:number = 0;

        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const dayOfWeek = currentDate.getDay();

            if (this.project.week_days === 5 && dayOfWeek !== 0 && dayOfWeek !== 6) {
                weekdays++;
            } else if (this.project.week_days === 6 && dayOfWeek !== 0) {
                weekdays++;
            } else if (this.project.week_days === 7) {
                weekdays++;
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return weekdays;
    }

    percentExpectedToday(key){
        if(this.projectData[key].target){
            return Number(((this.projectData[key].expectedToday / this.projectData[key].target) * 100).toFixed(2));
        }
        return 0
    }

    percentValue(key){
        if(this.projectData[key].target){
            return Number(((this.projectData[key].total / this.projectData[key].target) * 100).toFixed(2));
        }
        return 0
    }

    capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    setValueData(key) {
        const payload = {
            labels: [this.capitalizeFirstLetter(key), `Expected to Date`],
            datasets: [
                {
                    type: 'bar',
                    label: `${this.capitalizeFirstLetter(key)}`,
                    backgroundColor: this.projectData[key].colors[0],
                    data: []
                },
                {
                    type: 'bar',
                    label: `${this.capitalizeFirstLetter(key)} Left`,
                    backgroundColor: this.projectData[key].colors[2],
                    data: []
                }
            ]
        }
        const total = this.projectData[key].total
        const target = this.projectData[key].target
        if(target > total){
            payload.datasets[0].data.push(total)
            payload.datasets[1].data.push(target - total)
        } else {
            payload.datasets[0].data.push(total)
        }
        payload.datasets[0].data.push(this.projectData[key].expectedToday)
        return payload;
    }

    activated() {
        this.init()
        return this.populateCharts()
    }


}
