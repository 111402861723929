import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withKeys as _withKeys, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "p-mr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProjectsFilters = _resolveComponent("ProjectsFilters")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DateFormatted = _resolveComponent("DateFormatted")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AppTable = _resolveComponent("AppTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('project.list.title')), 1),
      _createVNode(_component_router_link, { to: _ctx.newRoute }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            icon: "pi pi-plus",
            label: "New"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_ProjectsFilters, {
      filters: _ctx.advFilters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => (_ctx.advFilters = $event)),
      onChange: _ctx.onFiltersChange
    }, null, 8, ["filters", "onChange"]),
    _createVNode(_component_AppTable, {
      ref: "dataTable",
      "striped-rows": false,
      filtersSchema: _ctx.filters,
      service: _ctx.service,
      stateKey: _ctx.stateKey,
      sortField: `created_at`,
      sortOrder: 1,
      onRowClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToDetail($event.data))),
      onEdit: _ctx.goToDetail,
      onResetFilters: _ctx.onReset
    }, {
      actions: _withCtx(({data}) => [
        _createElementVNode("span", _hoisted_2, [
          _withDirectives(_createVNode(_component_Button, {
            class: "p-button-info",
            icon: "pi pi-sitemap",
            onClick: _withModifiers(($event: any) => (_ctx.createActivity(data)), ["stop"])
          }, null, 8, ["onClick"]), [
            [
              _directive_tooltip,
              'Create Activity',
              void 0,
              { bottom: true }
            ]
          ])
        ])
      ]),
      columns: _withCtx(({onApplyFilter}) => [
        _createVNode(_component_Column, {
          field: "id",
          showFilterMatchModes: false,
          header: "Project ID"
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Search by Project ID",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.clear'),
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.apply'),
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "created_at",
          header: "Created At",
          sortable: true,
          style: {"min-width":"12rem"}
        }, {
          body: _withCtx(({data}) => [
            _createVNode(_component_DateFormatted, {
              date: data.created_at,
              format: "YYYY-MM-DD hh:mm A"
            }, null, 8, ["date"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "name",
          header: "Project",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "client.name",
          header: "Client",
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Search by Client name",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.clear'),
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.apply'),
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "work_type_description",
          header: "Work Type",
          sortable: true
        }),
        _createVNode(_component_Column, {
          header: "Status",
          field: "status",
          showFilterMatchModes: false,
          showFilterOperator: false
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(_ctx.$t(`project.status.${data.status}`)), 1)
          ]),
          filter: _withCtx(({filterModel}) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              options: _ctx.statusOptions,
              placeholder: "Select status",
              "option-value": "value",
              "option-label": "label"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              label: "apply",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["filtersSchema", "service", "stateKey", "onEdit", "onResetFilters"])
  ]))
}