import { Options, Vue }                    from "vue-class-component";
import {AppTable} from "@components";
import {SessionStorageEnum} from "@/utils/SessionStorageEnum";
import Table from "@components/AppTable/Table";
import { projectsService } from "@services/projects.service";
import {Project, ProjectStatusEnum} from "@/model/api/Project";
import {ProjectsRoutesEnum} from "@/modules/projects/router";
import {ProjectFilters} from "@/modules/projects/pages/projects/ProjectFilters";
import ProjectsFilters from "@/modules/projects/pages/projects/ProjectsFilters.vue";
import {FilterMatchMode} from "primevue/api";
import {ActivitiesRoutesEnum} from "@/modules/activities/router";

@Options({
  components: {
    AppTable,
    ProjectsFilters
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ProjectsRoutesEnum.PROJECT_PREFIX)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_PROJECTS);
      sessionStorage.removeItem(SessionStorageEnum.PROJECTS_ADVANCED_FILTERS);
    }

    next();
  },

  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },

})
export default class ProjectsPage extends Vue {

  filters: any = null;

  advFilters: ProjectFilters = new ProjectFilters();

  get statusOptions() {
    return [
      {label: this.$t(`project.status.${ProjectStatusEnum.OPEN}`), value: ProjectStatusEnum.OPEN},
      {label: this.$t(`project.status.${ProjectStatusEnum.CLOSED}`), value: ProjectStatusEnum.CLOSED},
    ];
  }

  private initFilter() {
    this.filters = {
      id: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      status: {
        value: ProjectStatusEnum.OPEN,
        matchMode: FilterMatchMode.EQUALS
      },
      'client.name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
    };
  }

  get service() {
    return projectsService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_PROJECTS
  }

  get table() {
    return this.$refs.dataTable as Table;
  }

  goToDetail(project: Project) {
    this.$router.push({
      name: ProjectsRoutesEnum.PROJECT_DETAIL,
      params: { projectId: project.id }
    })
  }

  created() {
    this.initFilter();
  }

  onReset() {
    this.advFilters = new ProjectFilters();
  }

  get newRoute() {
    return {
      name: ProjectsRoutesEnum.PROJECT_DETAIL,
      params: { projectId: 'new' }
    }
  }

  createActivity(project: Project) {
    const routeData = this.$router.resolve({
      name: ActivitiesRoutesEnum.ACTIVITY_DETAIL,
      params: { activityId: 'new' },
      query: { projectId: project.id }
    });
    window.open(routeData.href, '_blank');
  }

  private _updateTable() {
    const table = (this.$refs.dataTable as Table);
    table.applyFilter();
  }

  onFiltersChange() {
    const table = (this.$refs.dataTable as Table);

    if (table?.filters) {
      const filters = table.filters;

      // Filtro per Name
      if (filters.name) filters.name.value = this.advFilters?.name;

    }

    this._updateTable();

  }


}
