import {Options, Vue} from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {Activity} from "@/model/api/Activity";

@Options({
    inheritAttrs: false,
    components: {}
})
export default class ActivityKPI extends Vue {
    @Prop() activity!: Activity;

    @Prop() readonly isLoading!: boolean;

    @Watch('activity')
    onShiftChange() {
        if(this.activity){
            this.tonsData ??= this.setTonsData();
            this.cyclesData ??= this.setCyclesData();
            this.hoursData ??= this.setHoursData();
            this.budgetData ??= this.setBudgetData();
        }

    }

    tonsData = null;
    budgetData = null;
    cyclesData = null;
    hoursData = null;
    chartOptions = {
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true
                }
            }
        }
    }

    mounted() {
        this.onShiftChange()
    }

    get percent_tons(){
        if(this.activity?.target_tons){
            return Number(((this.activity.total_tons / this.activity.target_tons) * 100).toFixed(2));
        }
        return 0
    }

    get percent_cycles(){
        if(this.activity?.target_cycles){
            return Number(((this.activity.total_cycles / this.activity.target_cycles) * 100).toFixed(2));
        }
        return 0
    }

    get percent_time(){
        if(this.activity?.target_hours){
            return Number(((this.activity.total_hours / this.activity.target_hours) * 100).toFixed(2));
        }
        return 0
    }

    get percent_budget(){
        if(this.activity?.target_budget){
            return Number(((this.activity.total_budget / this.activity.target_budget) * 100).toFixed(2));
        }
        return 0
    }

    setBudgetData(){
        return {
            labels: ['Budget Left in %', 'Total Budget in %'],
            datasets: [
                {
                    data: this.activity.target_budget > this.activity.total_budget ? [100 - this.percent_budget, this.percent_budget] : [0, this.percent_budget],
                    backgroundColor: ['#6f42c1', '#94C142'],
                }
            ]
        };
    }

    setHoursData() {
        return {
            labels: ['Hours Left in %', 'Total Hours in %'],
            datasets: [
                {
                    data: this.activity.target_hours > this.activity.total_hours ? [100 - this.percent_time, this.percent_time] : [0, this.percent_time],
                    backgroundColor: ['#EF5350', '#2ea59f'],
                }
            ]
        };
    }

    setCyclesData() {
        return {
            labels: ['Cycles Left in %', 'Total Cycles in %'],
            datasets: [
                {
                    data: this.activity.target_cycles > this.activity.total_cycles ? [100 - this.percent_cycles, this.percent_cycles] : [0, this.percent_cycles],
                    backgroundColor: ['#66BB6A', '#42A5F5'],
                }
            ]
        };
    }

    setTonsData() {
        return {
            labels: ['Tons Left in %', 'Total Tons in %'],
            datasets: [
                {
                    data: this.activity.target_tons > this.activity.total_tons ? [100 - this.percent_tons, this.percent_tons] : [0, this.percent_tons],
                    backgroundColor: ['#FFA726', '#FF7043']
                }
            ]
        };
    }


}
