import {Company} from "@/model/api/Company";

export class Project {
	id: number;
	name: string;
	description: string;
	work_type_description: string;
	client: Company;
	client_id?: number;
	project_customers: Company[];
	expected_start: Date;
	expected_end: Date;
	week_days: number;
	status: boolean;

	get expectedStart(){
		return this.expected_start
	}

	set expectedStart(value: Date) {
		this.expected_start = value
	}

	get expectedEnd(){
		return this.expected_end
	}

	set expectedEnd(value: Date) {
		this.expected_end = value
	}

	constructor(data) {
		Object.assign(this, data);
	}
}

export enum ProjectStatusEnum {
	OPEN = 10,
	CLOSED = 20
}
