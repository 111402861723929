import {Options, Vue} from "vue-class-component";
import {Prop, Provide} from "vue-property-decorator";
import {Project} from "@/model/api/Project";
import {ProjectsRoutesEnum} from "@/modules/projects/router";
import {projectsService} from "@services/projects.service";
import {classToPlain, plainToClass} from "class-transformer";
import {ActivitiesRoutesEnum} from "@/modules/activities/router";
import moment from "moment";

@Options({
  components: {
  }
})
export default class ProjectPage extends Vue {

  @Prop()
  @Provide({ reactive: true })
  readonly projectId!: String;

  project: Project = null;
  requiredFields: string[] = [
      'name',
      'client_id',
      'status',
      'expected_start',
      'expected_end'
  ];

  get isNew() {
    return this.projectId === 'new';
  }

  get backRoute() {
    return { name: ProjectsRoutesEnum.PROJECT_LIST }
  }

  updateDates(res){
    ['expected_end', 'expected_start'].forEach((key) => {
      res[key] = moment(res[key]).format('YYYY-MM-DD')
    })
  }

  onSave(){

    let areThereAnyErrors: boolean

    this.requiredFields.forEach((field) => {
      if(this.project[field] === null || this.project[field] === undefined || this.project[field] === ''){
        this.$errorMessage(`Field ${field} is required`);
        areThereAnyErrors = true;
      }
    })

    if(areThereAnyErrors) return;

    if(!this.project.project_customers.length){
        this.$errorMessage(`Project must have at least one customer`);
        return;
    }

    this.$waitFor(
      async () => {
        const plainShift = classToPlain(this.project);
        this.updateDates(plainShift)

        if (this.isNew) {
          const res = await projectsService.create(plainShift as any)
          this.project = plainToClass(Project, res)
          await this.$router.replace({
            name: ProjectsRoutesEnum.PROJECT_DETAIL,
            params: {
              projectId: res.id
            }
          });
          this.$successMessage("Project successfully created");

        } else {
          const res = await projectsService.updatePatch(plainShift as any);
          this.project = plainToClass(Project, res);
          this.$successMessage("Project successfully updated");
        }
      },
      "Saving failed"
    )
  }

  createActivity(project: Project) {
    const routeData = this.$router.resolve({
      name: ActivitiesRoutesEnum.ACTIVITY_DETAIL,
      params: { activityId: 'new' },
      query: { projectId: project.id }
    });
    window.open(routeData.href, '_blank');
  }

  get tabs() {
    let i = 0;

    return [
      {
        label: this.$t('project.tabs.data'),
        icon: 'fas fa-sticky-note',
        to: { name: ProjectsRoutesEnum.PROJECT_DETAIL },
        class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
      },
      {
        label: this.$t('project.tabs.kpi'),
        icon: 'fas fa-chart-pie',
        to: { name: ProjectsRoutesEnum.PROJECT_KPI },
        disabled: this.isNew,
        class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
      },
      {
        label: this.$t('project.tabs.tickets'),
        icon: 'fas fa-clipboard',
        to: {name: ProjectsRoutesEnum.PROJECT_TICKETS},
        disabled: this.isNew,
        class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
      },
      {
        label: this.$t('project.tabs.activities'),
        icon: 'fas fa-sitemap',
        to: { name: ProjectsRoutesEnum.PROJECT_ACTIVITIES },
        disabled: this.isNew,
        class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
      },
      {
        label: this.$t('project.tabs.shifts'),
        icon: 'fas fa-road',
        to: { name: ProjectsRoutesEnum.PROJECT_SHIFTS },
        disabled: this.isNew,
        class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
      }
    ];
  }

  private async _loadProject() {
    this.$waitFor(
        async () => {
          this.project = await projectsService.getById(+this.projectId);
        }
    )
  }

  async created() {
    if (!this.isNew) {
      await this._loadProject();
    } else {
      this.project = new Project({
        project_customers: [],
      });
    }
  }

}
