import {Options, Vue} from "vue-class-component";
import {Inject, Prop} from "vue-property-decorator";
import {Project} from "@/model/api/Project";
import Table from "@components/AppTable/Table";
import {FilterMatchMode} from "primevue/api";
import {ActivitiesRoutesEnum} from "@/modules/activities/router";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {authStore} from "@/modules/auth/store";
import {AppTable} from "@components";
import {Activity, ActivityStatusEnum, ActivityWorkTypeEnum} from "@/model/api/Activity";
import {activitiesService} from "@services/activities.service";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";

@Options({
    inheritAttrs: false,
    components: {
        AppTable
    }
})
export default class ProjectActivities extends Vue {

    @Inject()
    readonly projectId!: string;

    @Prop() project!: Project;

    @Prop() readonly isLoading!: boolean;

    filters: any = null;

    get statusOptions() {
        return [
            {label: this.$t(`activity.status.${ActivityStatusEnum.OPEN}`), value: ActivityStatusEnum.OPEN},
            {label: this.$t(`activity.status.${ActivityStatusEnum.CLOSED}`), value: ActivityStatusEnum.CLOSED},
        ];
    }

    get workTypeOptions() {
        return [
            {label: this.$t(`activity.work_type.${ActivityWorkTypeEnum.IMPORT}`), value: ActivityWorkTypeEnum.IMPORT},
            {label: this.$t(`activity.work_type.${ActivityWorkTypeEnum.EXPORT}`), value: ActivityWorkTypeEnum.EXPORT},
            {label: this.$t(`activity.work_type.${ActivityWorkTypeEnum.ONSITE}`), value: ActivityWorkTypeEnum.ONSITE},
        ];
    }

    get service() {
        return activitiesService;
    }

    get table() {
        return this.$refs.dataTable as Table;
    }

    get imAdmin() {
        return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN
    }

    goToDetail(activity: Activity) {
        this.$router.push({
            name: ActivitiesRoutesEnum.ACTIVITY_DETAIL,
            params: { activityId: activity.id }
        })
    }

    createWRS(activity) {
        this.$router.push({
            name   : ShiftsRoutesEnum.SHIFTS_DETAIL,
            params : { shiftId: 'new' },
            query  : { activityId: activity.id }
        })
    }

    private initFilter() {
        this.filters = {
            id: {
                value: null,
                matchMode: FilterMatchMode.STARTS_WITH
            },
            name: {
                value: null,
                matchMode: FilterMatchMode.STARTS_WITH
            },
            status: {
                value: ActivityStatusEnum.OPEN,
                matchMode: FilterMatchMode.EQUALS
            },
            work_type: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            'customer.name': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            'project.id': {
                value: this.projectId,
                matchMode: FilterMatchMode.EQUALS
            }
        };
    }

    update() {
        console.debug("update");
        this.table.applyFilter();
    }

    created() {
        this.initFilter();
    }

}
