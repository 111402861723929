<template>

  <form class="p-formgroup">

    <!-- SKELETON -->
    <template v-if="isLoading">
    </template>
    <template v-else>
      <Fieldset>
        <template #legend>
          <i class="pi pi-paperclip" />
        </template>

        <section>
          <p>
            <strong>
              <span class="p-mr-2">Project: #ID</span>
              <router-link  :to="{
              name: projectRoute,
              params: {
                projectId: activity.project_id
              }
            }" target="_blank">
                <a>{{activity.project_id}} {{activity.project?.name}}</a>
              </router-link>
            </strong>
          </p>
          <div class="other">
            <div class="p-field" style="flex:1">
              <FloatLabel label="Activity Name">
                <InputText v-model="activity.name" style="width: 100%" />
              </FloatLabel>
            </div>
            <div class="p-field" style="flex:1">
              <FloatLabel label="WRS Prefix">
                <InputText v-model="activity.wrs_prefix" style="width: 100%" />
              </FloatLabel>
            </div>
          </div>
          <div class="other">
            <div class="p-field" style="flex:1">
              <FloatLabel label="Status">
                <Dropdown
                    style="width: 100%"
                    v-model="activity.status"
                    :options="statusOptions"
                    placeholder="Select status"
                    option-value="value"
                    option-label="label"
                />
              </FloatLabel>
            </div>

            <div class="p-field" style="flex:1">
              <FloatLabel label="Work Type">
                <Dropdown
                    style="width: 100%"
                    v-model="activity.work_type"
                    :options="workTypeOptions"
                    placeholder="Select work type"
                    option-value="value"
                    option-label="label"
                />
              </FloatLabel>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-6">

            </div>
          </div>
          <div class="p-field p-fluid">
            <FloatLabel label="Activity description and information">
              <Textarea
                  v-model="activity.description"
                  rows="4"
              />
            </FloatLabel>
          </div>
        </section>
      </Fieldset>

      <Fieldset class="p-mt-3">
        <template #legend>
          Targets
        </template>

        <section>
          <div class="other">
            <div class="p-field" style="flex:1">
              <FloatLabel label="Hours Value">
                <InputNumber v-model="activity.target_hours" style="width: 100%" />
              </FloatLabel>
            </div>
            <div class="p-field" style="flex:1">
              <FloatLabel label="Tons Value">
                <InputNumber v-model="activity.target_tons" style="width: 100%" />
              </FloatLabel>
            </div>
            <div class="p-field" style="flex:1">
              <FloatLabel label="Cycles Value">
                <InputNumber v-model="activity.target_cycles" style="width: 100%" />
              </FloatLabel>
            </div>
            <div class="p-field" style="flex:1">
              <FloatLabel label="Budget">
                <InputNumber v-model="activity.target_budget" style="width: 100%" mode="currency" currency="USD" locale="en-US" />
              </FloatLabel>
            </div>
          </div>
        </section>
      </Fieldset>

      <Fieldset class="p-mt-3">
        <template #legend>
          Work Requests Data
        </template>

        <div class="p-field p-fluid">
          <ActivityDataAutocomplete
              v-model="activity.job_site"
              field="job_site"
              @selected="onSelectedJob"
          />
        </div>

        <div class="p-field p-fluid">
          <FloatLabel :label="$t('activity.poc_name')">
            <InputText v-model="activity.poc_name" />
          </FloatLabel>
        </div>

        <div class="p-field p-fluid">
          <FloatLabel :label="$t('shift.poc_phone')">
            <InputText v-model="activity.poc_phone" />
          </FloatLabel>
        </div>

        <div class="p-field p-fluid">
          <FloatLabel :label="$t('shift.poc_email')">
            <InputText v-model="activity.poc_email" />
          </FloatLabel>
        </div>

        <div class="other">
          <div class="p-field" style="flex:1">
            <FloatLabel :label="$t('shift.cost_code')">
              <InputText style="width: 100%" v-model="activity.cost_code" />
            </FloatLabel>
          </div>
          <div class="p-field" style="flex:1">
            <TimeZoneDropdown
                style="width: 100%"
                v-model="activity.zone_name"
            />
          </div>
        </div>

        <div class="p-grid p-align-center">
          <div class="p-col-6">
            <div class="payment">
              <h6>Payment type:</h6>

              <div class="p-field-radiobutton">
                <RadioButton
                    id="cycle"
                    name="payment"
                    :value="paymentCYCLE"
                    v-model="activity.payment_type"
                />
                <label for="cycle">Cycle</label>
              </div>

              <div class="p-field-radiobutton">
                <RadioButton
                    id="hour"
                    name="payment"
                    :value="paymentHOUR"
                    v-model="activity.payment_type"
                />
                <label for="hour">Hourly</label>
              </div>

              <div class="p-field-radiobutton">
                <RadioButton
                    id="ton"
                    name="payment"
                    :value="paymentTON"
                    v-model="activity.payment_type"
                />
                <label for="ton">Ton</label>
              </div>
            </div>
          </div>
        </div>

        <div class="other">
          <div class="p-field" style="flex:1">
            <FloatLabel label="Customer">
              <AutoComplete style="width: 100%" :minLength="2" v-model="activity.customer" field="name" :suggestions="project_customers" @complete="search" @itemSelect="onCustomerSelected">
                <template #item="{ item }">
                  <div class="p-d-flex p-jc-between">
                    <div>
                      <span><i class="pi pi-user p-mr-3" /></span>
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </template>
              </AutoComplete>
            </FloatLabel>
          </div>

          <div class="p-field" style="flex: 1">
            <UserFlattenAutocomplete
                style="width: 100%;"
                :label="$t('activity.foreman')"
                v-model="activity.foreman"
                :disabled="!activity.customer"
                :params="userFilters"
                :queryOpts="usersQueryOpts"
                @select="onForemanSelected"
            />
          </div>


          <div class="p-field" style="flex:1">
            <ContractAutocomplete
                :disabled="!activity.customer"
                :customerId="activity.customer?.id"
                v-model="activity.contract"
                :noDropdown="true"
                label="Contract by customer"
                @select="onContractSelect"
            />
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-6">
            <div class="p-field p-fluid">
              <FloatLabel :label="$t('activity.smart_zone')">
                <ZoneAutocomplete
                    :customerId="activity.customer?.id"
                    v-model="activity.zone"
                    @select="onZoneSelect"
                    :disabled="!activity.customer"
                />
              </FloatLabel>
            </div>

            <div class="p-field-checkbox" v-if="activity.payment_type != paymentTON">
              <Checkbox
                  id="is_ton_requested"
                  v-model="activity.is_ton_requested"
                  :binary="true"
              />
              <label for="is_ton_requested">Tons requested on app</label>
            </div>

            <div class="p-field-checkbox">
              <Checkbox
                  id="can_upload_photo_dump"
                  v-model="activity.can_upload_photo_dump"
                  :binary="true"
              />
              <label for="can_upload_photo_dump">Upload dump photo</label>
            </div>

          </div>
          <div class="p-col-6">
            <div class="p-field p-fluid">
                <MaterialAutocomplete
                    :noDropdown="true"
                    :label="$t('shift.material')"
                    @select="onMaterialSelect"
                    v-model="activity.material"
                />
            </div>

            <div class="p-field-checkbox">
              <Checkbox
                  id="can_upload_photo_load"
                  v-model="activity.can_upload_photo_load"
                  :binary="true"
              />
              <label for="can_upload_photo_load">Upload load photo</label>
            </div>

          </div>
        </div>

        <div class="p-field p-fluid">
          <FloatLabel :label="$t('activity.wrs_description')">
            <Textarea v-model="activity.wrs_description"/>
          </FloatLabel>
        </div>

        <div class="p-field p-fluid">
          <FloatLabel :label="$t('shift.public_note')">
            <Textarea v-model="activity.note_public"/>
          </FloatLabel>
        </div>

      </Fieldset>

    </template>

  </form>

</template>

<style lang="scss" scoped>

%flex-gap {
  display: flex;
  gap: 1rem;
  flex-flow: row wrap;
}
.p-field{
  :deep input{
    width: 100%;
  }
}
.payment {
  @extend %flex-gap;
  align-items: center;
}

.other{
  @extend %flex-gap;
}

</style>

<script src="./ActivityData.ts"></script>
