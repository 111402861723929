
import {SessionStorageEnum} from "@/utils/SessionStorageEnum";
import {plainToClass} from "class-transformer";
import {Options, Vue} from "vue-class-component";
import {Model} from "vue-property-decorator";
import {ProjectFilters} from "./ProjectFilters";

@Options({})
export default class ProjectsFilters extends Vue {
  @Model("filters")
  advFilters: ProjectFilters;

  emitFilters() {
    this.saveAdvancedFilters();
    this.$emit("change", this.advFilters);
  }

  private saveAdvancedFilters() {
    sessionStorage.setItem(
        SessionStorageEnum.SHIFTS_ADVANCED_FILTERS,
        JSON.stringify(this.advFilters)
    );
  }

  private restoreAdvancedFilters() {
    const fromStorage = JSON.parse(
        sessionStorage.getItem(SessionStorageEnum.SHIFTS_ADVANCED_FILTERS)
    );

    this.advFilters = fromStorage
        ? plainToClass(ProjectFilters, fromStorage)
        : this.advFilters;
  }

  created() {
    this.restoreAdvancedFilters();
  }
}
