import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {Project, ProjectStatusEnum} from "@/model/api/Project";
import TabMenu from "primevue/tabmenu";
import {CompanyAutocomplete} from "@components";
import {Company} from "@/model/api/Company";

@Options({
    inheritAttrs: false,
    emits: ['save'],
    components: {
        TabMenu,
        CompanyAutocomplete
    }
})
export default class ProjectData extends Vue {
    @Prop() project!: Project;

    @Prop() readonly isLoading!: boolean;

    selectedCustomer: Company = null;
    week_days: any[] = [
        {value: 5, label: '5'},
        {value: 6, label: '6'},
        {value: 7, label: '7'},
    ]

    addCustomer(){
        if (
            this.selectedCustomer &&
            typeof this.selectedCustomer !== 'string' &&
            !this.project.project_customers.find((customer) => customer.id === this.selectedCustomer.id)){
            this.project.project_customers.push(this.selectedCustomer);
        }
        this.$nextTick(() => {
            this.selectedCustomer = null
        })
    }

    changeClient(){
        this.project.client_id = this.project.client.id;
    }

    onRemove(selectedCustomer: any){
        this.project.project_customers = this.project.project_customers.filter((customer) => customer.id !== selectedCustomer.id);
    }

    get statusOptions() {
        return [
            {label: this.$t(`project.status.${ProjectStatusEnum.OPEN}`), value: ProjectStatusEnum.OPEN},
            {label: this.$t(`project.status.${ProjectStatusEnum.CLOSED}`), value: ProjectStatusEnum.CLOSED},
        ];
    }
}
