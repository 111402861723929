import { CRUD }  from './base/crud';
import {Project} from "@/model/api/Project";
import {plainToClass} from "class-transformer";


class ProjectsService extends CRUD<Project, any> {
    readonly endPoint = `projects`;

    public async getById(id: number): Promise<Project> {
        const response = await super.getById(id);
        return  plainToClass(Project, response);
    }

}

export const projectsService = new ProjectsService();



