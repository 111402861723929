import { Options, Vue }                    from "vue-class-component";
import {AppTable} from "@components";
import {SessionStorageEnum} from "@/utils/SessionStorageEnum";
import Table from "@components/AppTable/Table";
import {FilterMatchMode} from "primevue/api";
import ActivitiesFilters from "@/modules/activities/pages/activities/ActivitiesFilters.vue";
import {ActivityFilters} from "@/modules/activities/pages/activities/ActivityFilters";
import {activitiesService} from "@services/activities.service";
import {Activity, ActivityStatusEnum, ActivityWorkTypeEnum} from "@/model/api/Activity";
import {ActivitiesRoutesEnum} from "@/modules/activities/router";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";

@Options({
  components: {
    AppTable,
    ActivitiesFilters
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ActivitiesRoutesEnum.ACTIVITY_PREFIX)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_ACTIVITIES);
      sessionStorage.removeItem(SessionStorageEnum.ACTIVITIES_ADVANCED_FILTERS);
    }

    next();
  },

  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },

})
export default class ActivitiesPage extends Vue {

  filters: any = null;

  advFilters: ActivityFilters = new ActivityFilters();

  get statusOptions() {
    return [
      {label: this.$t(`activity.status.${ActivityStatusEnum.OPEN}`), value: ActivityStatusEnum.OPEN},
      {label: this.$t(`activity.status.${ActivityStatusEnum.CLOSED}`), value: ActivityStatusEnum.CLOSED},
    ];
  }

  get workTypeOptions() {
    return [
      {label: this.$t(`activity.work_type.${ActivityWorkTypeEnum.IMPORT}`), value: ActivityWorkTypeEnum.IMPORT},
      {label: this.$t(`activity.work_type.${ActivityWorkTypeEnum.EXPORT}`), value: ActivityWorkTypeEnum.EXPORT},
      {label: this.$t(`activity.work_type.${ActivityWorkTypeEnum.ONSITE}`), value: ActivityWorkTypeEnum.ONSITE},
    ];
  }

  private initFilter() {
    this.filters = {
      id: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      status: {
        value: ActivityStatusEnum.OPEN,
        matchMode: FilterMatchMode.EQUALS
      },
      work_type: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      'customer.business_name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      'project.name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
    };
  }

  get service() {
    return activitiesService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_PROJECTS
  }

  get table() {
    return this.$refs.dataTable as Table;
  }

  goToDetail(activity: Activity) {
    this.$router.push({
      name: ActivitiesRoutesEnum.ACTIVITY_DETAIL,
      params: { activityId: activity.id }
    })
  }

  created() {
    this.initFilter();
  }

  onReset() {
    this.advFilters = new ActivityFilters();
  }

  get newRoute() {
    return {
      name: ActivitiesRoutesEnum.ACTIVITY_DETAIL,
      params: { activityId: 'new' }
    }
  }

  createWRS(activity) {
    this.$router.push({
      name   : ShiftsRoutesEnum.SHIFTS_DETAIL,
      params : { shiftId: 'new' },
      query  : { activityId: activity.id }
    })
  }

  private _updateTable() {
    const table = (this.$refs.dataTable as Table);
    table.applyFilter();
  }

  onFiltersChange() {
    const table = (this.$refs.dataTable as Table);

    if (table?.filters) {
      const filters = table.filters;

      // Filtro per Name
      if (filters.name) filters.name.value = this.advFilters?.name;

    }

    this._updateTable();

  }


}
